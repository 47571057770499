<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="../solution/img/顶部背景.png" alt="" />
    </div>
    <div class="bottomCss">
      <div class="xiangqing">
        <div class="title">城市交管解决方案</div>
        <div class="jieshao">
          城市交管解决方案通过将先进的信息管理、数据通讯传输、电子传感、控制及计算机等技术有效的集成运用于整个地面交通管理系统，建立在大范围、全方位发挥作用的实时、准确、高效的综合交通管理控制系统，实现了交通管理的动态化、全局化、自动化、智能化。
        </div>
        <div class="xiaobiaoqian">
          <img src="./img/小标签.png" alt="" />
          <span
            style="
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
            >系统组成</span
          >
        </div>
        <div class="jieshao">
          城市交管解决方案由应用子系统、信息服务中心和指挥控制中心三部分构成，包括数字交通执法系统、公路车辆智能检测记录系统，建立在大范围、全方位发挥作用的实时、准确、高效的综合交通管理控制系统，实现了交通管理的动态化、全局化、自动化、智能化。
        </div>
        <div style="width: 89%;margin-bottom:64px">
          <ul
            v-for="(item, index) in dataBox"
            :key="index"
          >
            <li
              style="
                font-size: 20px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                display: flex;
                margin-top: 24px;
              "
            >
              {{ item.text }}
            </li>
          </ul>
        </div>
        <div>
          <img
            style="width: 1186px;"
            src="./img/交管1.png"
            alt=""
          />
        </div>
        <div class="xiaobiaoqian">
          <img src="./img/小标签.png" alt="" />
          <span
            style="
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
            >系统架构</span
          >
        </div>
        <div>
          <img
            style="width: 1186px; margin-top: 36px; margin-bottom: 64px"
            src="./img/交管2.png"
            alt=""
          />
        </div>
        <div class="xiaobiaoqian">
          <img src="./img/小标签.png" alt="" />
          <span
            style="
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
            >方案优势</span
          >
        </div>
        <div>
          <img
            style="width: 1186px; margin-top: 36px; margin-bottom: 64px"
            src="./img/交管3.png"
            alt=""
          />
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataBox: [
          {
            text: "城市智慧停车管理服务平台",
          },
          {
            text: "互联网+停车服务系统",
          },
          {
            text: "路内停车管理系统",
          },
          {
            text: "城市停车诱导系统",
          },
          {
            text: "智能立体停车库管理系统",
          },
          {
            text: "智能BMS管理功能",
          },
          {
            text: "违法停车一体化抓拍系统",
          },
        ],
    };
  },
};
</script>

<style scoped>
.bottomCss {
  width: 100%;
  height: 100%;
  background: #f2f5f8;
  display: flex;
  justify-content: center;
}
.xiangqing {
  width: 1314px;
  /* height: 1404px; */
  margin-top: 64px;
  margin-bottom: 88px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 38px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  /* line-height: 0px; */
  margin-top: 54px;
  margin-bottom: 36px;
}
.jieshao {
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 38px;
  width: 1186px;
}
.xiaobiaoqian {
  display: flex;
  margin-bottom: 36px;
  margin-top: 48px;
  margin-left: -78.5%;
}
.xiaobiaoqian9 {
  display: flex;
  margin-bottom: 36px;
  margin-top: 48px;
  margin-left: -67%;
}
li::before {
  content: "";
  display: flex;
  width: 9px;
  height: 9px;
  background: #0940fd;
  border-radius: 50%;
  margin: 1%;
}
</style>